.App {
  text-align: center;
  background-color: #3f3d3b;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Nav-container {
  display: flex;
  flex-direction: row;
  border: 3px black solid;
  justify-items: center;
  align-content: center;
  background-color: #2a2725;
  justify-content: space-around;
}

.Nav-container ul {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-around;
  align-content: center;
}

.Nav-container li {
  display: flex;
  font-size: 3vh !important;
}

.Nav-container img {
  align-self: center;
  justify-content: center;
  align-content: center;
}

.bg-410 {
  font-size: 1.25rem;
  background-color: #2a2725;
  border-bottom: 1px solid white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 36px;
  left: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: white;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.25em;
}

.bm-item-list {
  color: white;
  padding: 1em;
  text-decoration: none;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  color: white;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.mobile-home-image {
  display: flex;
  width: 80%;
  height: 80%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid white;
}

.mobile-home-box {
  display: flex;
  flex-direction: column;
}

.mobile-home-box p {
  color: white;
  margin-left: 15px;
  margin-right: 10px;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 3px;
}

.link-no-dec {
  text-decoration: none;
  color: white;
  margin: 1vw;
}

.background-container {
  display: flex;
  flex-direction: column;
  background-color: #3f3d3b;
  min-height: 100vh;  
  max-width: 100vw;
}

.group-photo {
  border: 3px black solid;
  display: flex;
  align-self: center;
  box-shadow: 7px 3px 10px grey;
  margin-bottom: 3vh;
}

.cardflip-big-container {
  display: grid;
  height: auto;
  max-width: 100%;
  align-self: center;
  grid-template-columns: repeat(auto-fit, 400px);
  grid-template-rows: auto;
  grid-gap: 1rem;
  grid-auto-flow: dense;
}

.cardflip-big-container-mobile {
  display: grid;
  height: auto;
  max-width: 100%;
  align-self: center;
  grid-template-rows: auto;
  grid-gap: 1rem;
  grid-auto-flow: dense;
}

.cardflip-container {
  display: flex;
  flex: 1;
  margin: 3vh;
}

.flippy-box {
  width: 300px;
  height: auto;
  overflow-wrap: break-word;
  overflow: auto;
  border: 3px solid black;
}

.flippy-box-text {
  align-self: flex-end;
  font-size: 0.90em;
  white-space: pre-line;
  margin-top: 2%;
}

.flippy-box-text-backside {
  margin-top: 0;
  margin-bottom: 10;
  align-self: flex-end;
  font-size: 90%;
}

.flippy-box-text-backside-abstract {
  margin: 1;
  margin-bottom: 10;
  align-self: flex-end;
  font-size: 7pt;
}

.flippy-backside {
  background-color: grey;
  box-shadow: 7px, 3px, 10px, grey;
}

.publication-list-individual-container {
  display: flex;
  flex-direction: row;
  width: 80vw;
  margin: auto;
  justify-content: center;
}

.publication-list-individual-container p {
  align-self: center;
  color: white;
}

.publication-list-individual-container h1 {
  color: white
}

.grid-container-ack {
  display: grid;
  height: auto;
  width: 80%;
  max-width: 1100px;
  align-self: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.25rem;
  grid-auto-flow: dense;
  margin-top: 5vh;
}

.figure-container {
  background-color: #555251;
  align-content: center;
  align-items: center;
} 

.figure-container-ack img {
  object-fit: contain;
  width: 100%;
  max-height: 100%;
}

.caption-text-ack {
  font-size: 1.5em;
  font-family: 'Raleway';
  color: white;
}

.grid-container-members {
  display: grid;
  height: auto;
  width: 80%;
  max-width: 1100px;
  align-self: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
}

.grid-container-members-mobile {
  display: grid;
  height: auto;
  width: 100%;
}

.Footer-container {
  display: flex;
  flex-direction: row;
  border: 1.5px black solid;
  background-color: #2a2725;
  justify-content: space-around;
  margin-top: 10px;
}

.Footer-container p {
  justify-content: center;
}

.Footer-social-logos {
  display: flex;
  width: 50%;
  flex-direction: row;
  background-color: #2a2725;
  justify-content: center;
}

.Footer-social-logos a {
  align-self: center;
  margin-left: 2vw;
  margin-right: 2vw;
}

.Footer-social-logos img {
  background-color: #2a2725;
}

.Footer-contact-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0%;
  padding: 0%;
  width: 50%;
}

.Footer-contact-container a {
  text-decoration: none;
  color: #FFF;
  align-self: start;
  margin-left: 10vw;
}

.grid-container-resources {
  display: grid;
  width: 80%;
  max-width: 1100px;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.25rem;
}