.background-container {
  display: flex;
  flex-direction: column;
  background-color: #3f3d3b;
  min-height: 100vh;
}

.grid-container-home {
  display: grid;
  height: auto;
  width: 80%;
  max-width: 1100px;
  align-self: center;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-template-rows: auto;
  grid-gap: 1.25rem;
  grid-auto-flow: dense;
  margin-top: 5vh;
}

.home-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    box-shadow: 3px 3px 3px grey;
    border: 1px solid black;
    background-color: #555251;
    border-radius: 15px;
    margin-top: 5vh;
    width: 80vw;
}

.home-text h1 {
    font-size: 3em;
    color: white;
    justify-content: center;
    letter-spacing: 3px;
    margin: 5px;
}

.home-columns {
  display: flex;
  flex-direction: row;
  padding: 2%;
  margin-top: 1vh;
  align-items: center;
  justify-content: center;
}

.home-columns-box {
  margin-left: 2vw;
  justify-content: center;
  align-items: center;
}

.home-columns-box-image {
  margin-left: 2vw;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  max-height: 500px;
  align-self: center;
  border: 2px solid white;
}

.home-columns-box p {
  color: white;
  line-height: 2;
  margin-top: 0vh;
  margin-bottom: 1vh;
  font-family: Raleway;
  text-align: justify;
  font-size: 2vh;
}

.home-columns-box img {
  object-fit: cover;
}

.hr-home {
  border: 1px solid black;
}

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/raleway-v19-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/raleway-v19-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}