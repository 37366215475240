.Tile-Container {
    flex-direction: column;
    max-width: 500px;
    min-width: 315px;
    border: 3px solid black;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 0;
    background-color: #555251;
    margin-bottom: 10px;
    align-content: center;
}

.Tile-Container img {
    max-width: 50%;
    max-height: 300px;
    margin-top: 3px;
    border: 1.5px solid black;
}

.Tile-Element-Container {
    display: flex;
    flex-direction: row;
    border-top: 1px solid white;
}

.Tile-Container p {
    display: flex;
    font-size: 1.0em;
    color: white;
    min-width: 20%;
    text-align: justify;
    white-space: pre-line;
    padding: 2%;
    font-family: 'Raleway';
    margin-right: 10px;
}

.Tile-Container-Mobile img {
    display: flex;
    max-width: 40%;
    max-height: 300px;
    margin-top: 3px;
    align-self: center;
    border: 1.5px solid black;
}

.Tile-Element-Container-Mobile {
    display: flex;
    flex-direction: row;
    border-top: 1px solid white;
}

.Tile-Element-Container-Bio {
    display: flex;
    flex-direction: column;
    border-top: 1px solid white;
}

.Tile-Container-Mobile p {
    display: flex;
    font-size: 1em;
    color: white;
    min-width: 20%;
    text-align: justify;
    white-space: pre-line;
    font-family: 'Raleway';
    padding: 2%;
}

.Tile-Container-Mobile {
    display: flex;
    flex-direction: column;
    border: 2px solid black;
    width: 95%;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 0;
    background-color: #555251;
    margin-bottom: 10px;
}